import Auth from 'Auth';
import {Alert, Breadcrumb, Button} from 'antd';
import {useFetchReportMetaQuery} from 'api/reportsSlice';
import DataReport from 'components/dataReports/DataReport';
import DataReportEditor from 'components/dataReports/DataReportEditor';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const DataReportPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const reportSlug = location.pathname.split('/')[2];
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [version, setVersion] = useState(editorMode ? 'latest' : 'published');

  const {data, isLoading} = useFetchReportMetaQuery(
    {
      slug: reportSlug,
      version,
    },
    {
      skip: !reportSlug || reportSlug === 'new',
    }
  );

  useEffect(() => {
    if (!editorMode) {
      setVersion('published');
    } else if (params.get('version')) {
      setVersion(params.get('version'));
    } else {
      setVersion('latest');
    }
  }, [params.get('version')]);

  useEffect(() => {
    if ((!reportSlug || reportSlug === 'new') && data && editorMode) {
      navigate(`/data_report/${data.slug}/editor`);
    }
  }, [data]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/data_report/${editorMode ? 'editor' : ''}`}>
            Data Reports
          </Link>
        </Breadcrumb.Item>
        {data?.metadata?.path?.map((path) => (
          <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>
        ))}
        <Breadcrumb.Item>
          {data?.report_name ? (
            <Link to={`/data_report/${reportSlug}`}>{data?.report_name}</Link>
          ) : (
            'New'
          )}
        </Breadcrumb.Item>
        {editorMode && <Breadcrumb.Item>Editor</Breadcrumb.Item>}
      </Breadcrumb>
      <div className="flex-column">
        <DataReportEditor />
        {!data && (
          <Alert
            message={
              <div>
                Please define a report in the editor.
                <Button
                  type="primary"
                  onClick={() => setDrawerVisible(true)}
                  style={{display: 'inline-block', marginLeft: 10}}
                >
                  Open Editor
                </Button>
              </div>
            }
            type="info"
            style={{margin: 10}}
          />
        )}
        <DataReport loading={isLoading} />
      </div>
    </div>
  );
};

export default DataReportPage;
