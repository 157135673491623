import {
  CaretRightOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Auth from 'Auth';
import {setContrast} from 'Utils';
import {Button, Collapse, Divider, Spin, Tag, Tooltip} from 'antd';
import {useFetchPagesQuery} from 'api/cmsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useDeleteCategoryMutation,
  useDeleteTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import Input from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import TagsAndCategories from 'components/genericComponents/TagsAndCategories';
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const {Panel} = Collapse;

const DataPortalOverviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;

  const {data: pages, isLoading: pagesLoading} = useFetchPagesQuery(
    editorMode ? 'latest' : 'published'
  );
  const {data: tags, isLoading: tagsLoading} = useFetchTagsQuery('CMS');
  const {data: categories, isLoading: categoriesLoading} =
    useFetchCategoriesQuery('CMS');

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createOrUpdateTag] = useCreateOrUpdateTagMutation();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [deleteTag] = useDeleteTagMutation();

  const [defaultData, setDefaultData] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filterTagOptions, setFilterTagOptions] = useState([]);

  useEffect(() => {
    const options = tags?.map((tag) => ({
      ...tag,
      label: tag.name,
      value: tag.id,
      color: tag.color,
    }));
    options?.unshift({
      id: 'draft',
      name: 'Draft',
      label: 'Draft',
      value: 'draft',
      color: 'gold',
    });
    options?.unshift({
      id: 'unpublished',
      name: 'Unpublished Changes',
      label: 'Unpublished Changes',
      value: 'unpublished',
      color: 'blue',
    });
    setFilterTagOptions(options);
  }, [tags]);

  useEffect(() => {
    if (!filterTagOptions?.length) return;

    const params = new URLSearchParams(location.search);
    const tagParams = params.getAll('tag');
    const newTags =
      tagParams
        .map((t) => filterTagOptions?.find((d) => d.name === t))
        .filter((t) => t) || [];
    setFilterTags(newTags);
  }, [location.search, filterTagOptions]);

  const loop = (data) =>
    data?.map((item) => ({
      ...item,
      name: item.title,
      title: (
        <>
          <Link
            to={`/data_portal/${item.slug}/${editorMode ? 'editor' : ''}`}
            style={{fontSize: 16}}
          >
            {item.title}
          </Link>
          {!item.published_version && (
            <Tag
              color="gold"
              style={{marginRight: 3, marginLeft: 5, cursor: 'pointer'}}
              onClick={() => onChangeFilterTags(['draft'])}
              key="draft"
            >
              Draft
            </Tag>
          )}
          {editorMode &&
            item.published_version &&
            item.published_version !== item.version && (
              <Tag
                color="blue"
                style={{marginRight: 3, marginLeft: 5, cursor: 'pointer'}}
                onClick={() => onChangeFilterTags(['unpublished'])}
                key="unpublished"
              >
                Unpublished Changes
              </Tag>
            )}
          {item.tags?.map((tagId) => {
            const tag = tags?.find((t) => t.id === tagId);
            if (!tag) return null;

            const textColor = setContrast(tag.color);
            return (
              <Tag
                color={tag.color}
                style={{
                  marginRight: 1,
                  marginLeft: 5,
                  color: textColor,
                  cursor: 'pointer',
                  borderColor:
                    textColor === '#000000d9' ? '#d9d9d9' : tag.color,
                }}
                onClick={() => onChangeFilterTags([tag.id])}
                key={tag.name}
              >
                {tag.name}
              </Tag>
            );
          })}
        </>
      ),
      key: item.type + item.id,
      children: loop(item.children),
    }));

  useEffect(() => {
    if (!pages) return;
    setDefaultData(loop(pages.children) || []);
  }, [pages, tags, editorMode]);

  const renderCollapse = (data) =>
    data?.map((item) => {
      return item.type === 'node' ? (
        <Panel
          key={item.key}
          header={<h3>{item.name}</h3>}
          className="site-collapse-custom-panel"
        >
          <Collapse
            defaultActiveKey={item.children.map((c) => c.key)}
            ghost
            bordered={false}
            expandIcon={({isActive}) => (
              <CaretRightOutlined
                style={{paddingTop: 8}}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            {renderCollapse(item.children)}
          </Collapse>
        </Panel>
      ) : (
        <div className="site-collapse-custom-content" key={item.key}>
          {item.title}
        </div>
      );
    });

  const filteredDataList = useMemo(() => {
    // recursively filter defaultData
    // only return nodes that have children or match the search value
    // if a node name matches the search value, return the node and all its children
    const filterData = (data = []) => {
      const filtered = [];
      data.forEach((item) => {
        if (item.name.toLowerCase().includes(searchValue.toLowerCase())) {
          filtered.push(item);
        } else if (item.children) {
          const children = filterData(item.children);
          if (children.length) {
            filtered.push({...item, children});
          }
        }
      });
      return filtered;
    };
    const filteredData = searchValue ? filterData(defaultData) : defaultData;

    // recursively filter filteredData based on filterTags
    // only return nodes that have children
    // only return leaf nodes that have all the tags in filterTags
    // if the tag is 'draft' or 'unpublished', check if the page is a draft or has unpublished changes
    const filterTagsData = (data = []) => {
      const filtered = [];
      data.forEach((item) => {
        if (item.tags) {
          const hasTag = filterTags.every((t) => {
            if (t.id === 'draft') {
              return !item.published_version;
            }
            if (t.id === 'unpublished') {
              return (
                item.published_version &&
                item.published_version !== item.version
              );
            }
            return item.tags.findIndex((s) => s === t.id) !== -1;
          });
          if (hasTag) {
            filtered.push(item);
          }
        } else if (item.children) {
          const children = filterTagsData(item.children);
          if (children.length) {
            filtered.push({
              ...item,
              children,
            });
          }
        }
      });
      return filtered;
    };
    const filteredTagsData = filterTags
      ? filterTagsData(filteredData)
      : filteredData;

    return filteredTagsData;
  }, [defaultData, searchValue, filterTags]);

  const onChangeFilterTags = (value) => {
    const search = new URLSearchParams(location.search);
    // delete all tag params
    search.delete('tag');
    value.forEach((t) => {
      const tag = filterTagOptions?.find((d) => d.id === t);
      search.append('tag', tag?.name);
    });
    navigate(`${location.pathname}?${search.toString()}`);
  };

  return (
    <div>
      <h1>Data Portal Overview</h1>
      <div className="flex-row" style={{margin: '20px'}}>
        <Input
          allowClear
          label="Search"
          onChange={(e) => setSearchValue(e.target.value)}
          prefix={<SearchOutlined />}
          size="medium"
          style={{width: '450px'}}
          value={searchValue}
        />
        <SelectOptions
          allowClear={true}
          changeSelection={onChangeFilterTags}
          label="Filter by Tag"
          mode="tags"
          multiple={true}
          options={filterTagOptions}
          required={false}
          selectedOption={filterTags.map((t) => t.id)}
          size="medium"
          style={{width: '450px'}}
        />
      </div>
      {editorMode && (
        <Button
          type="primary"
          href="/data_portal/new/editor"
          style={{margin: 20}}
        >
          Create New Page
        </Button>
      )}
      <Spin spinning={pagesLoading || tagsLoading || categoriesLoading}>
        {filteredDataList?.length ? (
          <Collapse
            defaultActiveKey={filteredDataList.map((d) => d.key)}
            ghost
            bordered={false}
            expandIcon={({isActive}) => (
              <CaretRightOutlined
                style={{paddingTop: 6}}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            {renderCollapse(filteredDataList)}
          </Collapse>
        ) : (
          <p>No pages found.</p>
        )}
      </Spin>
      {editorMode && (
        <div>
          <Divider />
          <TagsAndCategories
            categories={categories}
            createOrUpdateCategory={(data) =>
              createOrUpdateCategory({
                ...data,
                belongs_to: 'CMS',
              })
            }
            createOrUpdateTag={(data) =>
              createOrUpdateTag({
                ...data,
                belongs_to: 'CMS',
              })
            }
            deleteCategory={(category_id) =>
              deleteCategory({
                category_id,
                belongs_to: 'CMS',
              })
            }
            deleteTag={(tag_id) =>
              deleteTag({
                tag_id,
                belongs_to: 'CMS',
              })
            }
            tags={tags}
          />
        </div>
      )}
      {Auth.permissions.access_to_data_portal_editor && (
        <Tooltip title={editorMode ? 'Preview mode' : 'Editor mode'}>
          <Button
            onClick={() =>
              navigate(`/data_portal${editorMode ? '' : '/editor'}`)
            }
            type="primary"
            style={{
              fontSize: '1.8em',
              height: '50px',
              left: 0,
              position: 'fixed',
              top: '15%',
              zIndex: 1001,
            }}
          >
            {editorMode ? <EyeOutlined /> : <EditOutlined />}
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default DataPortalOverviewPage;
