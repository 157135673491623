import {generateUniqueId} from 'Utils';
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Modal,
  Popconfirm,
  Spin,
  message,
} from 'antd';
import {
  useDeletePageMutation,
  useFetchPageQuery,
  usePublishPageMutation,
  useSavePageMutation,
} from 'api/cmsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import WidgetForm from 'components/dataPortal/WidgetForm';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import VersionItem from 'components/genericComponents/VersionItem';
import {isEqual} from 'lodash';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useLocation, useNavigate} from 'react-router-dom';

const {Panel} = Collapse;

const PageForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageSlug =
    location.pathname.split('/')[2] !== 'new'
      ? location.pathname.split('/')[2]
      : '';

  const [localPage, setLocalPage] = useState();
  const [localWidgets, setLocalWidgets] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newPageTitle, setNewPageTitle] = useState('');

  const {data: tags} = useFetchTagsQuery('CMS');
  const {data: categories} = useFetchCategoriesQuery('CMS');
  const {data, isLoading} = useFetchPageQuery(
    {
      slug: pageSlug,
      version: location.search.split('=')[1] || 'latest',
    },
    {skip: !pageSlug}
  );

  const [savePage] = useSavePageMutation();
  const [publishPage] = usePublishPageMutation();
  const [deletePage] = useDeletePageMutation();
  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createTag] = useCreateOrUpdateTagMutation();

  useEffect(() => {
    if (!data || isEqual(data, localPage)) return;
    setLocalPage({
      ...data,
    });
    setLocalWidgets(data.widgets);
  }, [data]);

  const handleAddWidget = () => {
    const widgets = localWidgets || [];
    const newWidget = {
      position: widgets.length,
      page: localPage?.id,
      id: generateUniqueId(widgets, 'int'),
    };
    setLocalWidgets([...widgets, newWidget]);
  };

  const handleDeleteWidget = (widgetId) => {
    const updatedWidgets = localWidgets.filter(
      (widget) => widget.id !== widgetId
    );
    setLocalWidgets(
      updatedWidgets.map((widget, index) => ({
        ...widget,
        position: index,
      }))
    );
  };

  const handleWidgetChanged = (value, field, widgetId) => {
    const updatedWidgets = [...localWidgets];
    const widgetIndex = updatedWidgets.findIndex((w) => w.id === widgetId);
    updatedWidgets[widgetIndex] = {
      ...updatedWidgets[widgetIndex],
      [field]: value,
    };
    setLocalWidgets(updatedWidgets);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedWidgets = [...localWidgets];
    const [removed] = updatedWidgets.splice(result.source.index, 1);
    updatedWidgets.splice(result.destination.index, 0, removed);
    setLocalWidgets(
      updatedWidgets.map((widget, index) => ({
        ...widget,
        position: index,
      }))
    );
  };

  const handlePageChanged = (value, type) => {
    const temp = {
      ...localPage,
      [type]: value,
    };
    setLocalPage(temp);
  };

  const handleSave = () => {
    const page = {
      ...localPage,
      // delete local_id from widgets
      widgets: localWidgets.map((widget) => {
        const {local_id, ...rest} = widget;
        return rest;
      }),
    };
    if (isEqual(page, data)) {
      message.info('No changes to save');
      return;
    }
    savePage(page)
      .unwrap()
      .then((res) => {
        message.success('Page saved successfully');
        navigate(`/data_portal/${res.slug}/editor`);
      });
  };

  const handleDelete = (version) => {
    if (localPage?.published && localPage?.published_version === version) {
      message.error('Unpublish the page before deleting');
      return;
    }
    if (pageSlug) {
      deletePage({
        slug: pageSlug,
        version,
      })
        .unwrap()
        .then(() => {
          message.success('Page deleted successfully');
        });
    }
    navigate(`/data_portal/editor`);
  };

  const handleClone = () => {
    if (!pageSlug) {
      message.error('Cannot clone a new page. Save this page first.');
    }
    const page = {
      title: newPageTitle,
      category: localPage?.category,
      tags: localPage?.tags,
      widgets: localWidgets,
    };
    savePage(page)
      .unwrap()
      .then((res) => {
        message.success('Page cloned successfully');
        setModalOpen(false);
        navigate(`/data_portal/${res.slug}/editor`);
      });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="flex-column">
        <InputField
          label="Title"
          onChange={(e) => handlePageChanged(e.target.value, 'title')}
          required={true}
          style={{width: '300px'}}
          value={localPage?.title}
        />
        <SelectOptions
          changeSelection={(value) => handlePageChanged(value, 'category')}
          label="Category"
          onAdd={(name) => {
            createOrUpdateCategory({
              name,
              belongs_to: 'CMS',
            })
              .unwrap()
              .then((res) => {
                handlePageChanged(res, 'category');
              });
          }}
          options={categories?.children}
          selectedOption={localPage?.category?.id}
          tree={true}
        />
        <SelectOptions
          changeSelection={(value) =>
            handlePageChanged(
              value.map((v) => tags?.find((c) => c.id === v) || null),
              'tags'
            )
          }
          label="Tags"
          onAdd={(name) => {
            createTag({
              name,
              belongs_to: 'CMS',
            })
              .unwrap()
              .then((res) => {
                handlePageChanged([...(localPage?.tags || []), res], 'tags');
              });
          }}
          options={tags?.map((c) => ({
            value: c.id,
            label: c.name,
            color: c.color,
          }))}
          mode="tags"
          multiple
          selectedOption={localPage?.tags?.map((t) => t.id)}
          style={{width: '100%'}}
        />
      </div>
      <Divider />
      <Alert
        description={
          <div>
            <h4>Superset:</h4>
            <ol>
              <li>
                <b>Dashboard ID</b>: go to the dashboard you want to embed and
                copy the number that appears after <b>'/dashboard/'</b> in the
                URL.
              </li>
              <li>
                <b>Embed ID</b>: go to the dashboard you want to embed, click on{' '}
                <b>'Embed dashboard'</b> option, and copy the ID.
              </li>
            </ol>
          </div>
        }
        type="success"
        style={{marginBottom: '20px'}}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="widgets">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="flex-column"
            >
              {localWidgets?.map((widget) => (
                <WidgetForm
                  key={widget.id || 'new'}
                  widget={widget}
                  setWidget={(value, field) =>
                    handleWidgetChanged(value, field, widget.id)
                  }
                  deleteWidget={handleDeleteWidget}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div>
        <Button
          type="primary"
          onClick={handleAddWidget}
          style={{marginTop: '10px'}}
        >
          Add Widget
        </Button>
      </div>
      <Divider />
      <div className="flex-row">
        <Button type="primary" onClick={handleSave}>
          Save Page
        </Button>
        <Button onClick={() => setModalOpen(true)}>Duplicate Page</Button>
        {pageSlug && (
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onConfirm={() => handleDelete()}
            title="Are you sure you want to delete this page?"
          >
            <Button type="danger">Delete Page</Button>
          </Popconfirm>
        )}
      </div>
      {localPage?.version_history?.length > 1 ? (
        <Collapse ghost style={{marginTop: '20px'}} expandIconPosition="end">
          <Panel header="Version History" key="1">
            {localPage?.version_history?.map((version) => {
              return (
                <VersionItem
                  currentVersion={localPage.version}
                  key={version.version}
                  publishedVersion={localPage.published_version}
                  version={version}
                  handleDelete={() => handleDelete(version.version)}
                  handlePublish={() => {
                    publishPage({
                      slug: pageSlug,
                      version: version.version,
                      published: !version.published,
                    })
                      .unwrap()
                      .then(() => {
                        message.success(
                          version.published
                            ? 'Unpublished successfully'
                            : 'Published successfully'
                        );
                      });
                  }}
                />
              );
            })}
          </Panel>
        </Collapse>
      ) : null}
      <Modal
        onCancel={() => setModalOpen(false)}
        onOk={handleClone}
        open={modalOpen}
        title="Duplicate Page"
      >
        <InputField
          label="New Page Title"
          onChange={(e) => setNewPageTitle(e.target.value)}
          required={true}
          value={newPageTitle}
        />
      </Modal>
    </Spin>
  );
};

export default PageForm;
