import {CloseCircleOutlined} from '@ant-design/icons';
import {setContrast} from 'Utils';
import {
  Button,
  Divider,
  Form,
  Popconfirm,
  Segmented,
  Tag,
  Tooltip,
  Tree,
} from 'antd';
import ColorPicker from 'components/genericComponents/ColorPicker';
import EditableField from 'components/genericComponents/EditableField';
import InputField from 'components/genericComponents/Input';
import React, {useEffect, useState} from 'react';

const {DirectoryTree} = Tree;

const Tags = ({tags, createOrUpdateTag, deleteTag}) => {
  const [form] = Form.useForm();
  const [filterTag, setFilterTag] = useState('all');
  const [displayedTags, setDisplayedTags] = useState(tags);
  const [options, setOptions] = useState([]);

  const alphabetOptions = Array.from({length: 26}, (_, i) => {
    const char = String.fromCharCode(i + 65);
    return {label: char, value: char, disabled: true};
  });

  const updateOptions = (tags) => {
    setOptions([
      {label: 'All', value: 'all'},
      {label: '#', value: '#'},
      ...alphabetOptions.map((option) => ({
        ...option,
        disabled: !tags?.some(
          (tag) =>
            tag.name?.startsWith(option.label) ||
            tag.name?.startsWith(option.label.toLowerCase())
        ),
      })),
    ]);
  };

  const handleCreateTag = (values) => {
    createOrUpdateTag(values);
    form.resetFields();
  };

  useEffect(() => {
    updateOptions(tags);
  }, [tags]);

  useEffect(() => {
    if (filterTag === 'all') {
      setDisplayedTags(tags);
    } else if (filterTag === '#') {
      // Display only tags that start with numbers or symbols
      setDisplayedTags(tags.filter((tag) => !/[a-zA-Z]/.test(tag.name[0])));
    } else {
      const filteredTags = tags.filter(
        (tag) =>
          tag.name?.startsWith(filterTag) ||
          tag.name?.startsWith(filterTag.toLowerCase())
      );
      setDisplayedTags(filteredTags);
    }
  }, [filterTag, tags]);

  return (
    <div>
      <h4>Tags</h4>
      <div className="flex-column">
        <Segmented
          onChange={(value) => setFilterTag(value)}
          options={options}
          style={{margin: 5, width: 'min-content'}}
          value={filterTag}
        />
        <div className="flex-column" style={{alignItems: 'start'}}>
          {displayedTags?.map((tag) => {
            const textColor = setContrast(tag.color);
            return (
              <Tag
                className="flex-row"
                closable
                closeIcon={
                  <Tooltip title="Delete tag">
                    <Popconfirm
                      title="Are you sure you want to delete this tag?"
                      onConfirm={() => deleteTag(tag.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <CloseCircleOutlined style={{color: textColor}} />
                    </Popconfirm>
                  </Tooltip>
                }
                color={tag.color}
                key={tag.id}
                style={{
                  marginRight: 1,
                  marginLeft: 5,
                  color: textColor,
                  borderColor:
                    textColor === '#000000d9' ? '#d9d9d9' : tag.color,
                  alignItems: 'center',
                }}
              >
                <ColorPicker
                  style={{marginLeft: 5, marginTop: 5}}
                  initialValue={tag.color}
                  onChange={(color) => createOrUpdateTag({...tag, color})}
                />
                <EditableField
                  key={tag.name}
                  value={tag.name}
                  onChange={(name) => createOrUpdateTag({...tag, name})}
                  size="small"
                  rules={[
                    {required: true, message: 'Tag name is required'},
                    {
                      max: 50,
                      message: 'Tag name must be less than 50 characters',
                    },
                    () => ({
                      validator(_, value) {
                        if (
                          tags.some(
                            (t) =>
                              t.name?.toLowerCase() === value?.toLowerCase() &&
                              t.id !== tag.id
                          )
                        ) {
                          return Promise.reject('Tag name already exists');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                />
              </Tag>
            );
          })}
        </div>
        <Form
          className="flex-row"
          form={form}
          onFinish={handleCreateTag}
          style={{margin: 5, alignItems: 'center'}}
        >
          <Form.Item name="color" style={{marginBottom: 0}}>
            <ColorPicker />
          </Form.Item>
          <Form.Item
            name="name"
            style={{marginBottom: 0}}
            rules={[
              {required: true, message: 'Tag name is required'},
              {
                max: 50,
                message: 'Tag name must be less than 50 characters',
              },
              () => ({
                validator(_, value) {
                  if (
                    tags.some(
                      (tag) => tag.name?.toLowerCase() === value?.toLowerCase()
                    )
                  ) {
                    return Promise.reject('Tag name already exists');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputField placeholder="New Tag Name" size="medium" />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Add New Tag
          </Button>
        </Form>
      </div>
    </div>
  );
};

const Categories = ({categories, deleteCategory, createOrUpdateCategory}) => {
  const [form] = Form.useForm();
  const [defaultData, setDefaultData] = useState([]);

  useEffect(() => {
    if (!categories) return;

    const loop = (data) => {
      const looped = data?.map((category) => {
        return {
          ...category,
          title: (
            <EditableField
              value={category.title}
              onChange={(name) => createOrUpdateCategory({...category, name})}
              rules={[
                {required: true, message: 'Category name is required'},
                {
                  max: 50,
                  message: 'Category name must be less than 50 characters',
                },
              ]}
            />
          ),
          key: category.id,
          children: category.children ? loop(category.children) : [],
        };
      });
      return looped;
    };

    setDefaultData(loop(categories?.children));
  }, [categories]);

  const handleCreateCategory = (values) => {
    createOrUpdateCategory(values);
    form.resetFields();
  };

  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dragPos = info.dragNode.pos.split('-');
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    // If the node is dropped in the same directory, do nothing
    if (dragPos.join('-') === dropPos.slice(0, -1).join('-')) {
      return;
    }

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const newData = [...defaultData];

    let dragObj;

    loop(newData, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(newData, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else if (
      info.node.props.children?.length &&
      info.node.props.expanded &&
      dropPosition === 1
    ) {
      loop(newData, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      let i;
      loop(newData, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    createOrUpdateCategory({
      id: dragKey,
      name: info.dragNode.title.props.value,
      parent: info.dropToGap ? info.node.parentId : dropKey,
    });

    setDefaultData(newData);
  };

  return (
    <div>
      <h4>Categories</h4>
      <div className="flex-column">
        {defaultData?.length ? (
          <DirectoryTree
            autoExpandParent
            className="draggable-tree"
            draggable
            icon={({data}) => (
              <Tooltip title="Delete Category">
                <Popconfirm
                  title="Are you sure you want to delete this category?"
                  onConfirm={() => deleteCategory(data.key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <CloseCircleOutlined />
                </Popconfirm>
              </Tooltip>
            )}
            onDrop={onDrop}
            selectable={false}
            showIcon
            treeData={defaultData}
            style={{width: '100%'}}
          />
        ) : null}
        <Form className="flex-row" form={form} onFinish={handleCreateCategory}>
          <Form.Item
            name="name"
            style={{marginBottom: 0}}
            rules={[
              {required: true, message: 'Category name is required'},
              {
                max: 50,
                message: 'Category name must be less than 50 characters',
              },
            ]}
          >
            <InputField placeholder="New Category Name" size="medium" />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Add New Category
          </Button>
        </Form>
      </div>
    </div>
  );
};

const TagsAndCategories = ({
  categories,
  createOrUpdateCategory,
  createOrUpdateTag,
  deleteCategory,
  deleteTag,
  tags,
}) => {
  return (
    <div>
      <Categories
        categories={categories}
        createOrUpdateCategory={createOrUpdateCategory}
        deleteCategory={deleteCategory}
      />
      <Divider />
      <Tags
        createOrUpdateTag={createOrUpdateTag}
        deleteTag={deleteTag}
        tags={tags}
      />
    </div>
  );
};

export default TagsAndCategories;
