import Auth from 'Auth';
import {generateUniqueId, getLetterByIndex} from 'Utils';
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Popconfirm,
  Spin,
  Switch,
  message,
} from 'antd';
import {
  useDeleteSeedVersionMutation,
  useFetchSeedDataQuery,
  useSetSeedMetaDataMutation,
} from 'api/seedsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import EditorButton from 'components/genericComponents/EditorButton';
import SelectOptions from 'components/genericComponents/SelectOptions';
import VersionItem from 'components/genericComponents/VersionItem';
import SeedColumnForm from 'components/seeds/SeedColumnForm';
import {isEqual} from 'lodash';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

const {Panel} = Collapse;

const SeedColumnList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SeedForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_source_manager_editor;
  const params = new URLSearchParams(location.search);
  const seedId = location.pathname.split('/')[2];

  const [columns, setColumns] = useState([]);
  const [edited, setEdited] = useState(false);
  const [localSeedMeta, setLocalSeedMeta] = useState({
    primary_key: [],
    enable_autoload: false,
    autoload_column: '',
  });
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  const getAllCols = () => {
    let allCols = [];
    columns.forEach((col) => {
      allCols.push(col.name);
      if (col.linked_columns?.length) {
        col.linked_columns.forEach((linkedCol) => {
          allCols.push(linkedCol.name);
        });
      }
    });
    return allCols;
  };

  const {data: seedData, isLoading: loadingData} = useFetchSeedDataQuery(
    {
      name: seedId,
      version: params.get('version') || 'latest',
    },
    {
      skip: !seedId,
    }
  );
  const {data: tags} = useFetchTagsQuery('SEEDS');
  const {data: categories} = useFetchCategoriesQuery('SEEDS');
  const [setSeedMetaData] = useSetSeedMetaDataMutation();
  const [deleteSeedVersion] = useDeleteSeedVersionMutation();
  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createOrUpdateTag] = useCreateOrUpdateTagMutation();

  useEffect(() => {
    if (!seedId) return;
    if (seedData?.metadata?.columns?.length) {
      setColumns(seedData.metadata.columns);
    } else if (seedData?.data?.[0]) {
      setColumns(
        Object.keys(seedData.data[0]).map((key) => ({
          name: key,
          type: 'text',
          key,
          col_id: generateUniqueId([], 'int'),
          linked_columns: [],
        }))
      );
    } else if (seedData?.draft_data?.[0]) {
      setColumns(
        Object.keys(seedData.draft_data[0]).map((key) => ({
          name: key,
          type: 'text',
          key,
          col_id: generateUniqueId([], 'int'),
          linked_columns: [],
        }))
      );
    } else {
      setColumns([]);
    }
  }, [seedData]);

  useEffect(() => {
    setLocalSeedMeta({
      ...localSeedMeta,
      ...seedData?.metadata,
    });
  }, [seedData, columns]);

  useEffect(() => {
    if (
      isEqual(seedData?.metadata, localSeedMeta) &&
      isEqual(seedData?.metadata?.columns, columns)
    ) {
      setEdited(false);
    } else {
      setEdited(true);
    }
  }, [localSeedMeta, columns]);

  useEffect(() => {
    // if there is only one autocomplete column, set it as the default
    if (
      columns?.filter((col) => col.type === 'autocomplete').length === 1 &&
      !localSeedMeta?.autoload_column
    ) {
      setLocalSeedMeta({
        ...localSeedMeta,
        autoload_column: columns?.find((col) => col.type === 'autocomplete'),
      });
    }
  }, [localSeedMeta?.enable_autoload]);

  const handleColumnChanged = (index, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = value;
    setEdited(true);
    setColumns(updatedColumns);
  };

  const handleAddColumn = () => {
    setColumns([
      ...columns,
      {
        name: getLetterByIndex(columns.length),
        type: 'text',
        col_id: generateUniqueId(columns, 'int'),
        linked_columns: [],
      },
    ]);
    setEdited(true);
  };

  const handleDelete = (index) => {
    const updatedColumns = [...columns];
    updatedColumns.splice(index, 1);
    setColumns(updatedColumns);
    setEdited(true);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedColumns = [...columns];
    const [removed] = updatedColumns.splice(result.source.index, 1);
    updatedColumns.splice(result.destination.index, 0, removed);
    const updatedColumnsMap = updatedColumns.map((col, idx) => {
      const newCol = {...col};
      newCol.index = idx;
      return newCol;
    });
    setColumns(updatedColumnsMap);
    setEdited(true);
  };

  const handleSave = () => {
    setSeedMetaData({
      ...localSeedMeta,
      seed_name: seedId,
      columns,
    })
      .unwrap()
      .then(() => {
        setEdited(false);
        navigate(`/source_manager/${seedId}/editor`);
        message.success('Changes saved successfully');
      });
  };

  return (
    <>
      <Drawer
        title="Source Schema Editor"
        placement="left"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={600}
        getContainer={false}
        extra={
          <Switch
            loading={loadingData}
            disabled={!seedData?.metadata?.columns?.length}
            checked={seedData?.metadata?.published}
            onChange={(checked) =>
              setSeedMetaData({
                version: seedData?.metadata?.version,
                seed_name: seedId,
                published: checked,
              })
            }
            checkedChildren="Unpublish"
            unCheckedChildren="Publish"
          />
        }
        style={{
          position: 'fixed',
          overflowY: 'auto',
          minHeight: 'calc(100% - 64px)',
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Spin spinning={loadingData}>
          <div className="flex-column">
            <h3>{localSeedMeta.seed_name}</h3>
            <SelectOptions
              label="Category"
              options={categories?.children}
              selectedOption={localSeedMeta?.category?.id}
              changeSelection={(value) => {
                setLocalSeedMeta({
                  ...localSeedMeta,
                  category: value,
                });
              }}
              onAdd={(name) => {
                createOrUpdateCategory({
                  name,
                  belongs_to: 'SEEDS',
                })
                  .unwrap()
                  .then((res) => {
                    setLocalSeedMeta({
                      ...localSeedMeta,
                      category: res,
                    });
                  });
              }}
              tree={true}
            />
            <SelectOptions
              style={{width: '100%'}}
              label="Tags"
              options={tags?.map((c) => ({
                value: c.id,
                label: c.name,
                color: c.color,
              }))}
              selectedOption={localSeedMeta?.tags?.map((t) => t.id)}
              changeSelection={(value) =>
                setLocalSeedMeta({
                  ...localSeedMeta,
                  tags: value.map((v) => tags?.find((c) => c.id === v) || null),
                })
              }
              onAdd={(name) => {
                createOrUpdateTag({
                  name,
                  belongs_to: 'SEEDS',
                })
                  .unwrap()
                  .then((res) => {
                    setLocalSeedMeta({
                      ...localSeedMeta,
                      tags: [...(localSeedMeta.tags || []), res],
                    });
                  });
              }}
              multiple
              mode="tags"
            />
            <div>
              <Alert
                description={
                  <div>
                    Notes:
                    <ul>
                      <li>
                        <b>
                          Column names and column order must match the schema in
                          DBT.
                        </b>
                      </li>
                      <li>
                        Unpublished sources are only visible to editors, and
                        changes cannot be finalized.
                      </li>
                      <li>Published sources are visible to all users.</li>
                    </ul>
                  </div>
                }
                type="info"
                style={{marginBottom: '20px'}}
              />
            </div>
            <h4>Columns</h4>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="columns">
                {(provided) => (
                  <SeedColumnList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {columns?.map((column, idx) => (
                      <SeedColumnForm
                        key={column?.col_id}
                        column={column}
                        columns={columns}
                        setColumn={(value) => handleColumnChanged(idx, value)}
                        handleDelete={() => handleDelete(idx)}
                        idx={idx}
                      />
                    ))}
                    {provided.placeholder}
                  </SeedColumnList>
                )}
              </Droppable>
            </DragDropContext>

            <Button
              type="primary"
              onClick={handleAddColumn}
              style={{margin: '10px 0', width: '150px'}}
            >
              Add Column
            </Button>
            <Divider />

            <h4>Unique Identifier</h4>
            <Alert
              description={
                <div>
                  Select all columns that make up a unique entry in this seed.
                  <br />
                  If there are multiple columns, they will be concatenated.
                  <br />
                  If none are selected, all entries will be marked as
                  duplicates.
                </div>
              }
              type="success"
              style={{marginBottom: '20px'}}
            />
            <SelectOptions
              selectedOption={localSeedMeta.primary_key || []}
              changeSelection={(val) =>
                setLocalSeedMeta({...localSeedMeta, primary_key: val})
              }
              options={getAllCols()}
              multiple={true}
              label="Unique Identifier"
              style={{width: '100%'}}
            />
            <Divider />
            <h4>Autoload</h4>
            <Alert
              description={
                <div>
                  Select the columns you want to autoload from the source table.
                  <br />
                  Autoload is used to populate the seed automatically. It can
                  only be enabled if there are autocomplete columns in the
                  configuration.
                </div>
              }
              type="success"
              style={{marginBottom: '20px'}}
            />
            <Checkbox
              disabled={!columns?.some((col) => col.type === 'autocomplete')}
              checked={localSeedMeta.enable_autoload}
              onChange={(e) =>
                setLocalSeedMeta({
                  ...localSeedMeta,
                  enable_autoload: e.target.checked,
                })
              }
            >
              Enable
            </Checkbox>
            {localSeedMeta?.enable_autoload ? (
              <div className="flex-column" style={{margin: '10px 0'}}>
                <Alert
                  description={
                    <div>
                      If applicable, it is recommended to add the Read Only
                      checkbox attribute for the selected Autoload columns. This
                      will ensure end users can only edit allowed columns.
                    </div>
                  }
                  type="info"
                  style={{marginBottom: '20px'}}
                />
                <SelectOptions
                  label="Columns to Populate"
                  style={{width: '100%'}}
                  required={true}
                  selectedOption={localSeedMeta.autoload_column}
                  changeSelection={(val) =>
                    setLocalSeedMeta({
                      ...localSeedMeta,
                      autoload_column: val,
                    })
                  }
                  options={columns
                    ?.filter((col) => col.type === 'autocomplete')
                    .map((col) => {
                      return {
                        label: `${col.name} + ${col.linked_columns?.map((c) => c.name).join(' + ')} (${col.source_table})`,
                        value: col.id,
                      };
                    })}
                />
              </div>
            ) : null}
            <div className="flex-row" style={{margin: '10px 0'}}>
              <Popconfirm
                title={<div>Are you sure you want to save these changes?</div>}
                onConfirm={handleSave}
                okText="Yes"
                cancelText="No"
                okButtonProps={{danger: true, type: 'default'}}
                cancelButtonProps={{type: 'primary'}}
                disabled={!edited}
              >
                <Button type="primary" disabled={!edited} loading={loadingData}>
                  Save Changes
                </Button>
              </Popconfirm>
              <Popconfirm
                title={
                  <div>
                    Are you sure you want to discard this draft?
                    <br />
                    This cannot be undone.
                  </div>
                }
                onConfirm={() => {
                  // revert to original state
                  setColumns(seedData?.metadata.columns);
                  setLocalSeedMeta({
                    ...seedData?.metadata,
                  });
                  setEdited(false);
                }}
                okText="Yes"
                cancelText="No"
                okButtonProps={{danger: true, type: 'default'}}
                cancelButtonProps={{type: 'primary'}}
                disabled={!edited}
              >
                <Button loading={loadingData} disabled={!edited}>
                  Discard Changes
                </Button>
              </Popconfirm>
            </div>

            {localSeedMeta?.version_history?.length > 1 ? (
              <Collapse
                ghost
                style={{marginTop: '20px'}}
                expandIconPosition="end"
              >
                <Panel header="Version History" key="1">
                  {localSeedMeta?.version_history?.map((version) => {
                    const _version = {
                      ...version,
                      slug: seedId,
                    };
                    return (
                      <VersionItem
                        currentVersion={localSeedMeta.version}
                        key={version.version}
                        publishedVersion={localSeedMeta.published_version}
                        version={_version}
                        handleDelete={() => deleteSeedVersion(version)}
                        handlePublish={() => {
                          setSeedMetaData({
                            version: version.version,
                            seed_name: seedId,
                            published: !version.published,
                          })
                            .unwrap()
                            .then(() => {
                              message.success({
                                content: version.published
                                  ? 'Unpublished successfully'
                                  : 'Published successfully',
                              });
                            });
                        }}
                      />
                    );
                  })}
                </Panel>
              </Collapse>
            ) : null}
          </div>
        </Spin>
      </Drawer>
      <EditorButton
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        path={`/source_manager/${seedId}`}
        key={seedId + editorMode}
      />
    </>
  );
};

export default SeedForm;
