import {CloseCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {setContrast} from 'Utils';
import {Button, Divider, Input, Select, Tag, Tooltip, TreeSelect} from 'antd';
import React, {useRef, useState} from 'react';

const SelectOptions = ({
  allowClear = true,
  bordered = true,
  changeSelection,
  disabled = false,
  label,
  mode,
  multiple = false,
  onAdd,
  options = [],
  placeholder,
  required,
  selectedOption,
  size = 'large',
  style = {},
  tree = false,
}) => {
  const [name, setName] = useState('');
  const inputRef = useRef(null);

  const tagRender = (props) => {
    const {label, value} = props;
    const tag = options.find((o) => o.value === value);
    if (!tag) {
      return null;
    }
    const color = tag?.color ?? '#d9d9d9';
    const textColor = setContrast(tag?.color);
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        closable={true}
        color={color}
        key={value}
        onMouseDown={onPreventMouseDown}
        onClose={(e) =>
          onChangeSelection(selectedOption?.filter((o) => o !== value))
        }
        closeIcon={<CloseCircleOutlined style={{color: textColor}} />}
        style={{
          marginRight: 3,
          color: textColor,
          borderColor: textColor === '#000000d9' ? '#ebebeb' : color,
          cursor: 'pointer',
        }}
      >
        {label}
      </Tag>
    );
  };

  const addItem = (e) => {
    e.preventDefault();
    if (name) {
      onAdd(name);
    }
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onChangeSelection = (o) => {
    changeSelection(o?.value ? o?.value : o);
  };

  const dropdownRender = (menu) => (
    <>
      {menu}
      {onAdd && (
        <>
          <Divider style={{margin: '8px 0'}} />
          <div className="flex-row" style={{padding: '0 8px 4px'}}>
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={(value) => setName(value.target.value)}
              style={{width: '50%'}}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add item
            </Button>
          </div>
        </>
      )}
    </>
  );

  return (
    <div style={{position: 'relative'}}>
      <Tooltip title={placeholder} placement="top">
        {label && (
          <label
            style={{
              backgroundColor: 'white',
              color: 'gray',
              fontSize: 10,
              left: 6,
              padding: '0 5px',
              position: 'absolute',
              top: -6,
              zIndex: 2,
            }}
          >
            {label}
            {required && <span>*</span>}
          </label>
        )}
        {tree ? (
          <TreeSelect
            allowClear={allowClear}
            autoClearSearchValue
            bordered={bordered}
            disabled={disabled}
            dropdownRender={dropdownRender}
            filterTreeNode={(input, node) =>
              node?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onInputKeyDown={(e) => {
              if (e.key === 'Enter') {
                addItem(e);
              }
            }}
            onSelect={(value, node) => changeSelection(node)}
            onSearch={setName}
            placeholder={placeholder}
            searchValue={name}
            showSearch
            size={size}
            style={{width: 300, ...style}}
            tagRender={mode === 'tags' ? tagRender : null}
            treeCheckable={multiple}
            treeData={options}
            treeDefaultExpandAll
            value={selectedOption}
            getPopupContainer={(triggerNode) => triggerNode} // ties the dropdown position to the input (otherwise it will scroll with the page)
          />
        ) : (
          <Select
            allowClear={allowClear}
            autoClearSearchValue
            bordered={bordered}
            disabled={disabled}
            dropdownRender={dropdownRender}
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            mode={multiple ? 'multiple' : null}
            onChange={onChangeSelection}
            onInputKeyDown={(e) => {
              if (e.key === 'Enter') {
                addItem(e);
              }
            }}
            onSearch={setName}
            optionFilterProp="label"
            options={options.map((v) =>
              v?.value
                ? {
                    value: v.value,
                    label: v.label ?? v.value,
                    disabled: v.disabled,
                  }
                : {value: v, label: v}
            )}
            placeholder={placeholder}
            searchValue={name}
            showSearch
            size={size}
            style={{width: 300, ...style}}
            tagRender={mode === 'tags' ? tagRender : null}
            value={selectedOption}
            getPopupContainer={(triggerNode) => triggerNode} // ties the dropdown position to the input (otherwise it will scroll with the page)
          />
        )}
      </Tooltip>
    </div>
  );
};

export default SelectOptions;
